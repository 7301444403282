<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ALLOCATION OF FUND</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              @change="selected_category"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-if="!is_generated"
              v-model="total_fund"
              class="mx-2"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              label="Total Fund"
              type="number"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="total_fund"
              class="mx-2"
              dense
              outlined
              label="Total Fund"
              readonly
            ></v-text-field>
          </v-flex>
          <div v-if="is_generated">
            Edit:
            <v-icon class="mr-2" color="error"
                    @click="is_can_edit_total_fund=true">
              {{ icons.mdiPencil }}
            </v-icon>
            Print:
            <v-icon v-if="!printt" class="mr-2" color="warning"
                    @click="print_data">
              {{ icons.mdiCloudPrint }}
            </v-icon>
          </div>

          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12"
                 v-if="(employee_id===1 || employee_id===2|| employee_id===187)&& !is_generated && !is_approved">
            <v-btn
              class="w-full"
              color="success"
              @click="save_fund"
              v-if="!saving_data"
            >
              Save Fund
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" md="12"
                 v-if="(employee_id===1 || employee_id===2|| employee_id===187) && is_generated&& total_percentage_raw==='100.000' && !is_approved">
            <v-btn
              class="w-full"
              color="info"
              @click="approved_allocation"
              v-if="!saving_data"
            >
              Approved Allocation of Fund
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-5"
                  v-if="is_generated && total_percentage_raw<100">
          <v-flex xs12 md3>
            <v-select
              v-model="type_of_bank"
              class="mx-2"
              dense
              outlined
              label="Purpose"
              :items="['OTHER ALLOCATION','TRUST FUND','EXPENSE','RESERVE FUND','INVESTMENT','REMITTANCE']"
              @change="selected_type_of_bank"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="type_of_bank==='OTHER ALLOCATION'">
            <v-select
              v-model="category_id_2"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              @change="selected_category2"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-5"
                  v-if="is_generated && total_percentage_raw<100">
          <v-flex xs12 md3>
            <v-select
              v-model="bank_id"
              class="mx-2"
              dense
              outlined
              label="Bank Code"
              :items="bank_items"
              item-value="id"
              item-text="bank_code"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="allocation_percentage"
              class="mx-2"
              dense
              outlined
              label="Allocation Percentage"
              type="number"
              @keyup="computation_allocated"
              @change="computation_allocated"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="allocated_fund"
              class="mx-2"
              dense
              outlined
              label="Allocated Fund"
              readonly
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2|| employee_id===187)">
            <v-btn
              class="w-full"
              color="info"
              @click="save_fund_allocated"
              v-if="!saving_data"
            >
              Save Allocated Fund
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-data-table class="mt-4" dense :headers="headers" :items="data_items" hide-default-footer
                    disable-pagination
                    :sort-by="['type_of_bank','bank_depository.bank_code']">
        <template v-slot:item.allocated_fund="{ item }">
          <tr>
            <td>
              {{ formatPrice(item.allocated_fund) }}
            </td>
          </tr>
        </template>

        <template v-slot:item.action="{ item }">
          <tr>
            <td v-if="item.id>0">
              <v-icon class="mr-2" color="error" @click="delete_fund(item)">
                {{ icons.mdiDelete }}
              </v-icon>
              <v-icon class="mr-2" color="info" @click="edit(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </td>
            <td v-if="item.id>0 && is_approved">
              <v-icon class="mr-2" color="info" @click="add_schedule(item)">
                {{ icons.mdiPlus }}
              </v-icon>
            </td>
            <td v-if="item.id>0 && is_approved && item.schedule_data.length>0">
              <v-data-table class="mt-4" dense :headers="headers2" :items="item.schedule_data"
                            hide-default-footer
                            disable-pagination
                            :sort-by="['date',]">
                <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>

          <td>
            <v-icon class="mr-2" color="info" @click="edit_schedule(item)">
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon class="mr-2" color="error" @click="delete_fund_schedule(item)">
              {{ icons.mdiDelete }}
            </v-icon>
          </td>
          </tr>
        </template>
      </v-data-table>
      </td>
      </tr>
</template>
</v-data-table>
</v-card>
<v-dialog v-model="is_can_edit_total_fund" persistent max-width="60%">
  <v-card>
    <v-card-text>
      <v-form ref="form" class="multi-col-validation mx-2 mt-5">
        <v-layout row wrap class="align-center mx-2 mt-5" v-if="is_generated">
          <v-flex xs12 md3>
            <v-text-field
              v-model="month_of_string"
              class="mx-2"
              dense
              outlined
              label="Month Of"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="category_string"
              class="mx-2"
              dense
              outlined
              label="Category"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="total_fund_raw"
              class="mx-2"
              dense
              outlined
              label="Allocated Fund"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2|| employee_id===187)">
            <v-btn
              class="w-full"
              color="primary"
              @click="update_total_fund"
              v-if="!saving_data"
            >
              Update Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
  <v-btn class="mt-4" color="error" @click="selected_category"> Close</v-btn>
</v-dialog>
<v-dialog v-model="is_edit" persistent max-width="60%">
  <v-card>
    <v-card-text>
      <v-form ref="form" class="multi-col-validation mx-2 mt-5">
        <v-layout row wrap class="align-center" v-if="is_generated">
          <v-flex xs12 md3>
            <v-text-field
              v-model="selected_data.type_of_bank"
              class="mx-2"
              dense
              outlined
              label="Purpose"
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-5" v-if="is_generated">
          <v-flex xs12 md3>
            <v-text-field
              v-model="selected_data.bank_depository.bank_code"
              class="mx-2"
              dense
              outlined
              label="Bank Code"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="allocation_percentage"
              class="mx-2"
              dense
              outlined
              label="Allocation Percentage"
              type="number"
              @keyup="computation_allocated"
              @change="computation_allocated"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="allocated_fund"
              class="mx-2"
              dense
              outlined
              label="Allocated Fund"
              readonly
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2|| employee_id===187)">
            <v-btn
              class="w-full"
              color="primary"
              @click="update_fund_allocated"
              v-if="!saving_data"
            >
              Update Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
  <v-btn class="mt-4" color="error" @click="is_edit = false"> Close</v-btn>
</v-dialog>
<v-dialog v-model="is_edit_schdule" persistent max-width="60%">
  <v-card>
    <v-card-text>
      <v-form ref="form" class="multi-col-validation mx-2 mt-5">
        <v-layout row wrap class="align-center mx-2 mt-5" v-if="is_generated">
          <v-flex xs12 md3>
            <v-text-field
              v-model="schedule_date_edit"
              class="mx-2"
              dense
              outlined
              label="Schedule Date"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="schedule_amount_edit"
              class="mx-2"
              dense
              outlined
              label="Schedule Amount"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2|| employee_id===187)">
            <v-btn
              class="w-full"
              color="primary"
              @click="update_fund_allocated_schedule"
              v-if="!saving_data"
            >
              Update Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
  <v-btn class="mt-4" color="error" @click="is_edit_schdule = false"> Close</v-btn>
</v-dialog>
<v-dialog v-model="is_add_schedule" persistent max-width="60%">
  <v-card>
    <v-card-text>
      <v-form ref="form3" class="multi-col-validation mx-2 mt-5">
        <v-layout row wrap class="align-center mx-2 mt-5" v-if="is_generated">
          <v-flex xs12 md3>
            <v-text-field
              v-model="schedule_date"
              class="mx-2"
              dense
              outlined
              label="Schedule Date"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="schedule_amount"
              class="mx-2"
              dense
              outlined
              label="Schedule Amount"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2|| employee_id===187)">
            <v-btn
              class="w-full"
              color="primary"
              @click="add_schedule_data"
              v-if="!saving_data"
            >
              Add Schedule
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
  <v-btn class="mt-4" color="error" @click="is_add_schedule = false"> Close</v-btn>
</v-dialog>
<snack-bar-dialog
  :snackbar_flag="this.snackbar"
  :color="this.snackbar_color"
  :snackbar_text="this.snackbar_text"
/>
</div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus, mdiCloudPrint} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      printt: false,
      is_can_edit_total_fund: false,
      is_edit: false,
      is_edit_schdule: false,
      is_generated: false,
      is_approved: false,
      is_add_schedule: false,
      saving_data: false,
      alert: false,
      alert_customize: false,
      alert_message: '',
      alert_message_customize: '',

      selected_data: {type_of_bank: '', bank_depository: []},
      selected_data_schedule: {},

      schedule_date: '',
      schedule_amount: '',
      schedule_date_edit: '',
      schedule_amount_edit: '',
      month_of_id: '',
      month_of_string: '',
      month_of_items: [],
      category_id: '',
      category_string: '',
      category_id_2: '',
      category_items: [],
      bank_id: '',
      bank_items: [],
      total_fund: '0',
      total_fund_raw: 0,
      total_percentage_raw: 0,
      allocation_id: 0,
      type_of_bank: '',
      allocation_percentage: '',
      allocated_fund: '',
      allocated_fund_raw: '',
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Bank Code', value: 'bank_depository.bank_code', sortable: true},
        {text: 'Type of Bank', value: 'type_of_bank', sortable: true},
        {text: 'Percentage', value: 'allocation_percentage', sortable: true},
        {text: 'Allocated Fund', value: 'allocated_fund', sortable: true},
        {text: 'Action', value: 'action', sortable: true},
      ],
      data_items: [],
      headers2: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Date', value: 'date', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Action', value: 'action', sortable: false},
      ],
      data_items2: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiDelete,
          mdiClose,
          mdiPencil,
          mdiPlus,
          mdiCloudPrint,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
      ]),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee',]),
      ...mapActions('bank_depository', ['bank_depository_type_of_bank',]),
      ...mapActions('allocation_of_funds', ['create_allocation_of_fund', 'initialize_allocation_of_fund', 'update_allocation_of_fund', 'approved_allocation_of_fund']),
      ...mapActions('allocation_of_fund_data', ['create_allocation_of_fund_data', 'update_allocation_of_fund_data', 'delete_allocation_of_fund_data']),
      ...mapActions('allocation_of_fund_data_schedule', ['create_allocation_of_fund_schedule', 'update_allocation_of_fund_schedule', 'delete_allocation_of_fund_schedule']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
            this.month_of_items.splice(0, 0, {
              id: parseInt(this.month_of_items[0].id) + 1,
              month_of: moment(this.month_of_items[0].month_of, 'MMMM YYYY').add(1, 'M').format('MMMM YYYY')
            })
            this.month_of_items.splice(0, 0, {
              id: parseInt(this.month_of_items[0].id) + 1,
              month_of: moment(this.month_of_items[0].month_of, 'MMMM YYYY').add(1, 'M').format('MMMM YYYY')
            })
            this.month_of_items.splice(0, 0, {
              id: parseInt(this.month_of_items[0].id) + 1,
              month_of: moment(this.month_of_items[0].month_of, 'MMMM YYYY').add(1, 'M').format('MMMM YYYY')
            })
            this.category_items = response.data[0].category
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
      },
      computation_allocated() {
        this.allocated_fund_raw = (parseFloat(this.allocation_percentage) / 100) * parseFloat(this.total_fund_raw)
        this.allocated_fund = this.formatPrice(this.allocated_fund_raw)
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        this.is_can_edit_total_fund = false
        this.is_generated = false
        this.is_approved = false
        this.category_id_2 = ''
        this.allocation_id = 0
        this.data_items = []
        const data = new FormData()
        data.append('category_id', this.category_id);
        data.append('month_of_id', this.month_of_id);
        this.initialize_allocation_of_fund(data)
          .then(response => {
            if (response.data.length > 0) {
              this.alert = false
              this.is_generated = true
              this.is_approved = response.data[0].is_approved
              this.allocation_id = response.data[0].id
              this.total_fund_raw = response.data[0].total_fund
              this.total_fund = this.formatPrice(response.data[0].total_fund)
              var arr = []
              var total_percentage = 0;
              var total_allocated = 0;
              response.data[0].allocation_data.forEach(function (item) {
                total_percentage += parseFloat(item.allocation_percentage)
                total_allocated += parseFloat(item.allocated_fund)
                arr.push({
                  id: item.id,
                  bank_depository: item.bank_depository,
                  type_of_bank: item.type_of_bank,
                  allocation_percentage: item.allocation_percentage,
                  allocated_fund: item.allocated_fund,
                  schedule_data: item.schedule_data,
                })
              })
              arr.push({
                id: 0,
                bank_depository: {bank_code: '*****TOTAL*****'},
                type_of_bank: '**********',
                allocation_percentage: total_percentage.toFixed(3),
                allocated_fund: total_allocated,
                schedule_data: [],
              })
              this.total_percentage_raw = total_percentage.toFixed(3)
              this.data_items = arr
            }
          })
          .catch(error => {
            console.log(error)
          })
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(this.category_id)
        if (index != -1) {
          this.category_string = this.category_items[index].category
        }
        var index2 = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of_id)
        if (index2 != -1) {
          this.month_of_string = this.month_of_items[index2].month_of
        }
      },
      async selected_type_of_bank() {
        this.bank_items = []
        const data = new FormData()
        data.append('category_id', this.type_of_bank === 'OTHER ALLOCATION' ? this.category_id_2 : this.category_id);
        data.append('type_of_bank', this.type_of_bank);
        await this.bank_depository_type_of_bank(data)
          .then(response => {
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category2() {
        this.bank_items = []
        this.alert = false
        if (this.category_id === this.category_id_2) {
          this.alert = true
          this.alert_message = 'Please select other Category'
          this.saving_data = false
        } else {
          this.selected_type_of_bank()
        }
      },
      delete_fund_schedule(item) {
        if (confirm('PROCEED?')) {
          const data = new FormData()
          data.append('id', item.id);
          this.delete_allocation_of_fund_schedule(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      delete_fund(item) {
        if (confirm('PROCEED?')) {
          const data = new FormData()
          data.append('id', item.id);
          this.delete_allocation_of_fund_data(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      async edit(item) {
        this.selected_data = item
        this.type_of_bank = await item.type_of_bank
        this.bank_id = await item.bank_depository.id
        await this.selected_type_of_bank
        this.allocation_percentage = await item.allocation_percentage
        this.allocated_fund = await item.allocated_fund
        this.is_edit = true
      },
      async edit_schedule(item) {
        this.selected_data_schedule = item
        this.schedule_date_edit = item.date
        this.schedule_amount_edit = item.amount
        this.is_edit_schdule = true
      },
      async add_schedule(item) {
        this.selected_data = item
        this.type_of_bank = await item.type_of_bank
        this.bank_id = await item.bank_depository.id
        await this.selected_type_of_bank
        this.allocation_percentage = await item.allocation_percentage
        this.allocated_fund = await item.allocated_fund
        this.is_add_schedule = true
      },
      save_fund() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('month_of_id', this.month_of_id);
          data.append('category_id', this.category_id);
          data.append('total_fund', this.total_fund);
          this.create_allocation_of_fund(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      save_fund_allocated() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('allocation_id', this.allocation_id);
          data.append('bank_id', this.bank_id);
          data.append('type_of_bank', this.type_of_bank);
          data.append('allocation_percentage', this.allocation_percentage);
          data.append('allocated_fund', this.allocated_fund_raw);
          this.create_allocation_of_fund_data(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      update_total_fund() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.allocation_id);
          data.append('allocated_fund', this.total_fund_raw);
          this.update_allocation_of_fund(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      update_fund_allocated_schedule() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('fund_data_id', this.selected_data_schedule.fund_data_id);
          data.append('id', this.selected_data_schedule.id);
          data.append('date', this.schedule_date_edit);
          data.append('amount', this.schedule_amount_edit);
          this.update_allocation_of_fund_schedule(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      update_fund_allocated() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('allocation_id', this.allocation_id);
          data.append('id', this.selected_data.id);
          data.append('allocation_percentage', this.allocation_percentage);
          data.append('allocated_fund', this.allocated_fund_raw);
          this.update_allocation_of_fund_data(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      approved_allocation() {
        this.saving_data = true
        this.alert = false
        const data = new FormData()
        data.append('allocation_id', this.allocation_id);
        this.approved_allocation_of_fund(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
            this.selected_category()
          })
          .catch(error => {
            console.log(error)
          })
      },
      add_schedule_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form3.validate()) {
          const data = new FormData()
          data.append('fund_data_id', this.selected_data.id);
          data.append('date', this.schedule_date);
          data.append('amount', this.schedule_amount);
          this.create_allocation_of_fund_schedule(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async print_data() {
        var month_of = ''

        var index2 = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of_id)
        if (index2 != -1) {
          month_of = this.month_of_items[index2].month_of
        }
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []

        widths = [180, 100, 80, 80,]
        payments_array.push([
          {text: 'Bank Code', alignment: 'left', style: 'main_info'},
          {text: 'Type of Bank', alignment: 'left', style: 'main_info'},
          {text: 'Percentage', alignment: 'left', style: 'main_info'},
          {text: 'Allocated Fund', alignment: 'left', style: 'main_info'},
        ])
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.bank_depository.bank_code,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.type_of_bank,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.allocation_percentage + ' %',
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.allocated_fund === 0
                  ? ''
                  : (item.allocated_fund / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
        })

        var payments_array2 = []
        var widths2 = []

        widths2 = [200, 80, 80, 80,]
        payments_array2.push([
          {text: 'Bank Code', alignment: 'left', style: 'main_info'},
          {text: 'Type of Bank', alignment: 'left', style: 'main_info'},
          {text: 'Schedule', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
        ])
        this.data_items.forEach(function (item, index, payment) {
          item.schedule_data.forEach(function (item2) {
            payments_array2.push([
              {
                text: item.bank_depository.bank_code,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.type_of_bank,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item2.date,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text:
                  item2.amount === 0
                    ? ''
                    : (item2.amount / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          })
        })

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'ALLOCATION OF FUND FOR THE MONTH OF ' + month_of,
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },

            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'ALLOCATION OF FUND SCHEDULES',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              table: {
                widths: widths2,
                body: payments_array2,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: 'ALBERT C. ARROCO JR.',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'CEO/PRESIDENT',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      },
    }
  }
</script>
